import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import {mapState} from "vuex";

const state = {
    init: true
};
const global_mix = {
    computed: {
        // 使用对象展开运算符将 getter 混入 computed 对象中
        ...mapState('user', [
            'username',
            'token',
            'userInfo',
            'configCon'
        ])
    },
}
Vue.mixin(global_mix);


export default new Vuex.Store({
    plugins: [createPersistedState({
        key: 'mtual',
        paths: ['user']
    })],
    state,
    modules: {
        user
    }
})
