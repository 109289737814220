<template>
    <div class="home">
        <div class="home-header">
            <div class="header-left">
                <p class="title">{{$t('home.address')}}</p>
                <p class="discription">
                    {{$t('home.title')}}<br/>
                    {{$t('home.dis')}}
                </p>
                <div class="join-btn" @click="handleClose">
                    Bind inviter
                </div>
            </div>
            <div class="header-right">
                <img src="../assets/home-header-rightbg.jpg" class="img">
            </div>
        </div>
        <div class="container animate__animated animate__slideInUp">
            <ul class="wrap">
                <li class="item">
                    <img src="../assets/item01.png" class="icon"/>
                    <p class="tit">Total number of invites</p>
                    <p class="val" v-if="userInfo">{{userInfo.invitee_count}}</p>
                    <p class="val" v-if="!userInfo">--</p>
                </li>
                <li class="item">
                    <img src="../assets/item02.png" class="icon"/>
                    <p class="tit">Invite Deposit</p>
                    <p class="val" v-if="userInfo">{{userInfo.deposit_amt}}</p>
                    <p class="val" v-if="!userInfo">--</p>
                </li>
                <li class="item">
                    <img src="../assets/item03.png" class="icon"/>
                    <p class="tit">Invite Balance</p>
                    <p class="val" v-if="userInfo">{{userInfo.wallet_val}}</p>
                    <p class="val" v-if="!userInfo">--</p>
                </li>
            </ul>
<!--            <el-image-->
<!--                    :src="homeContainer"-->
<!--                    class="image"-->
<!--                    fit="contain"-->
<!--            ></el-image>-->
        </div>
        <!--        <div class="avatar-info">-->
        <!--            <div class="info-container">-->
        <!--                <div class="container">-->
        <!--                    AVATARDAO is a Venture Capital DAO organization, aim to supporting bold entrepreneurs better-->
        <!--                    building-->
        <!--                    Crypto & WEB3.-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <el-dialog
                title="Bind inviter"
                :visible.sync="dialogVisible"
                class="bind-box"
                :before-close="handleClose">
            <div class="input-box">
                <el-input   v-model="mnemonic" placeholder="Please enter wallet private key"></el-input>
            </div>
            <div slot="footer" class="footer-btn">
    <el-button @click="handleClose">Cancel</el-button>
    <el-button type="primary" :loading="isLoadind" @click="bind">Bind</el-button>
  </div>
        </el-dialog>
    </div>

</template>

<script>
    // @ is an alias to /src
    import homeContainer from "../assets/home-container.jpg";
    import {Init} from "../plugins/web3";
    import avatar from "../ABI/avatar.json";
    import {mapState, mapActions} from "vuex";

    export default {
        name: 'Home',
        data() {
            return {
                homeContainer: homeContainer,
                dialogVisible:false,
                mnemonic:'',
                isLoadind:false,
                Summary:null
            }
        },
        computed: {
            ...mapState('user', ['userInfo','username','token'])
        },
        mounted() {
            if(this.token){
                this.info();
            }
        },
        methods: {
            ...mapActions('user', ['info']),
            async bind(){
                if(!this.userInfo){
                    this.$message({
                        message: "Please link wallet",
                        type: "info",
                        offset:100
                    });
                    return;
                }
                if(this.mnemonic.length!=64){
                    this.$message({
                        message: "Please enter the correct private key",
                        type: "info",
                        offset:100
                    });
                    return;
                }
                this.isLoadind = true;
                try {
                    let res = await this.api.auth(this.token).bindInvite({
                        privateKey:this.mnemonic,
                        type:this.username.indexOf("0x") !=-1?'bsc':'tron'
                    });
                    if(res.data.code===200){
                        this.isLoadind = false;
                        this.$message({
                            message: "Bind successfully",
                            type: "success",
                            offset:100
                        });
                        this.mnemonic = "";
                        this.info();
                    }
                } catch (e) {
                    this.isLoadind = false;
                }

            },
            handleClose(){
                this.dialogVisible = !this.dialogVisible;
                this.mnemonic = "";
            },
            toPath(item) {
                if (!this.userInfo) {
                    this.$message({
                        message: "Please connect the wallet to operate",
                        type: "info",
                        offset:100
                    });
                    return;
                }
                if (item.link) {
                    window.open(item.path);
                } else {
                    this.$router.push(item.path);
                }
            },
            async callCon() {
                //初始化合约
                let provider = Init();
                this.web3 = new this.Web3(provider);
                this.myContract = await new this.web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                console.log("myContract", this.myContract);
                let result = await this.myContract.methods.operateAddress().call();
                console.log("result", result);
            },

        }
    }
</script>

<style lang="scss" scoped>

    @font-face {
        font-family: AvatarTit;
        src: url('../plugins/css/AlibabaSans-Black.otf') format("truetype");
    }

    .home-header {
        width: 1200px;
        margin: 100px auto 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-left {
            width: 557px;

            .title {
                font-family: AvatarTit;
                margin-bottom: 58px;
                color: #110D0A;
                font-size: 44px;
            }

            .discription {
                margin: 50px 0;
                line-height: 44px;
                font-size: 20px;
                color: #454350;
            }

            .join-btn {
                min-width: 160px;
                height: 50px;
                background: linear-gradient(349deg, #6236D2 0%, #3A56FF 100%);
                box-shadow: 0px 72px 32px 0px rgba(27, 117, 240, 0.11);
                color: #FFFFFF;
                font-size: 20px;
                font-weight: 500;
                border-radius: 5px;
                text-align: center;
                line-height: 50px;
                box-sizing: border-box;
                cursor: pointer;
                text-transform: uppercase;
            }
        }

        .header-right {
            width: 483px;
            height: 552px;
            animation: dong 3s infinite;

            .img {
                width: 100%;
                height: 100%;
            }
        }
    }

    @keyframes dong {
        0% {
            transform: translate(0px, 0px);
        }
        50% {
            transform: translate(0px, -30px);
        }
        100% {
            transform: translate(0px, 0px);
        }
    }

    .container {
        width: 100%;
        height: 677px;
        background-image: url("../assets/home-container.jpg");
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .image {
            width: 100%;
            height: 100%;
        }
        .wrap{
            margin: 0 auto;
            width: 1200px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .item{
                width: 33%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                padding: 15px 0 25px 0;
                box-shadow: 0 0 20px #e2dbc1b3;
                border-radius: 15px;
                background: #FFFFFF;
                .icon{
                    width: 150px;
                    height: 150px;
                }
                .tit{
                    margin-bottom: 15px;
                    font-size: 14px;
                }
                .val{
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
    }

    .avatar-info {
        margin: 50px auto;
        width: 1200px;
        height: 242px;
        background-color: #3A56FF;
        padding-top: 12px;
        display: flex;
        justify-content: flex-end;

        .info-container {
            width: 1188px;
            height: 242px;
            border: 1px solid #3A56FF;
            background-color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;

            .container {
                width: 1086px;
                height: 242px;
                line-height: 60px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                color: #111C5D;
            }
        }
    }

    @media screen and (max-width: 1440px) {
        .home-header {
            width: 100%;
            box-sizing: border-box;
            padding: 0 100px;
        }
    }

    @media screen and (max-width: 1200px) {
        .home-header {
            width: 100%;
            justify-content: center;

            .header-left {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            .header-right {
                display: none;
            }
        }
        .avatar-info {
            width: 85%;

            .info-container {
                width: 98%;

                .container {
                    width: 80%;
                    font-size: 18px;
                }
            }
        }
        .home-header .header-left .discription {
            text-align: center;
        }
    }

    @media screen and (max-width: 750px) {
        .home-header {
            padding: 0 20px;
        }
        .home-header .header-left .title {
            margin-bottom: 10px;
            font-size: 35px;
        }
        .home-header .header-left .discription {
            margin: 10px 0;
            font-size: 18px;
        }
        .home-header .header-left .join-btn {
            margin-top: 20px;
            min-width: 300px;
            height: 60px;
            line-height: 60px;
        }
        .avatar-info {
            width: 90%;
        }
        .avatar-info .info-container .container {
            width: 90%;
        }
        .container {
            padding: 10px 0;
            height: auto;
            box-sizing: border-box;
            .wrap{
                width: 100%;
                justify-content: center;
                align-items: center;
                .item{
                    margin-bottom: 10px;
                    width: 90%;
                }
            }
        }
    }

</style>
<style lang="scss">
    .bind-box .el-dialog{
        width: 500px;
    }
    @media screen and (max-width: 1200px) {
        .container {
            .el-image__inner {
                object-fit: cover !important;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .bind-box .el-dialog{
            width: 300px;
        }
    }

</style>
