import VueI18n from 'vue-i18n';
import Vue from "vue";
import en from '../language/en';
import zh from '../language/zh';
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'
import stores from "../store/index";

Vue.config.lang = stores.state.user.lang || 'en';
Vue.use(VueI18n);
/*默认语言*/
const i18n = new VueI18n({
    locale: Vue.config.lang,
    messages: {
        en: {
            ...en,
            ...enLocale
        },
        zh: {
            ...zh,
            ...zhLocale
        },
    }
});
ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n;
