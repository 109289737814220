<template>
    <div class="header" :class="{'noFixed':drawer}">
        <div class="header-content">
            <div class="header-left">
                <div class="logo" @click="toHome">
                    MTUAL DEBT
                </div>
            </div>
            <div class="haeder-right">
                <p v-for="(item,index) in headerList" :key="index"
                   @click="toPath(item)"
                   class="nav-item">
                    {{item.title}}
                </p>
<!--                <p class="link-wallet" v-if="!userInfo" @click="connect">-->
<!--                    <i class="iconfont icon-qianbao"></i>-->
<!--                </p>-->
                <el-dropdown  trigger="click"
                             @command="handleCommand" :show-timeout="0"
                >
                    <p class="user-wallet" v-if="username">
                        {{walletSub(username, 4)}}
                    </p>
                    <p class="link-wallet" v-else>
                        <i class="iconfont icon-qianbao"></i>
                    </p>
                    <el-dropdown-menu
                            class="user-menu"
                            slot="dropdown">
                        <el-dropdown-item
                                v-for="(items,keys) in walletList"
                                :key="keys"
                                :command="items">
                            <i class="iconfont" :class="items.icon"></i>
                            <span>{{items.tit}}</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

            </div>
        </div>
        <el-drawer
                :visible.sync="drawer"
                :before-close="handleClose">
            <div class="avatar-container">
                <div class="logo">
                    MTUAL DEBT
                </div>
                <el-dropdown  trigger="click"
                              @command="handleCommand" :show-timeout="0"
                >
                    <p class="link-wallet" v-if="username">
                        {{walletSub(username, 4)}}
                    </p>
                    <p class="link-wallet" v-else>
                        <i class="iconfont icon-qianbao"></i>
                    </p>
                    <el-dropdown-menu
                            slot="dropdown">
                        <el-dropdown-item
                                v-for="(items,keys) in walletList"
                                :key="keys"
                                :command="items">
                            <i class="iconfont" :class="items.icon"></i>
                            <span>{{items.tit}}</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <div class="line"></div>
            </div>
            <ul class="nav-wrap">
                <li v-for="(item,index) in rightList" :key="index"
                    class="nav-item"
                    @click="toPath(item)"
                >
                    <p class="border"></p>
                    <span>{{item.title}}</span>
                </li>
            </ul>
        </el-drawer>
    </div>
</template>

<script>
    import {Init} from "../plugins/web3";
    import {mapState, mapMutations, mapActions} from "vuex";
    import avatar from "../ABI/avatar.json";
    import {  toUtf8Bytes } from '../utils/ethersUtils';
    import utils from '../utils';
    const TRX_MESSAGE_HEADER = '\x19TRON Signed Message:\n32';
    // it should be: '\x15TRON Signed Message:\n32';
    const ETH_MESSAGE_HEADER = '\x19Ethereum Signed Message:\n32';
    export default {
        name: "myheader",
        data() {
            return {
                drawer: false,
                headerList: [{
                    title: "Twitter",
                    link: true,
                    path: "https://twitter.com/esmdym6"
                },{
                    title: "Telegram",
                    link: true,
                    path: "https://t.me/mutualdebts"
                }],
                rightList: [{
                    title: "Twitter",
                    link: true,
                    path: "https://twitter.com/esmdym6"
                },{
                    title: "Telegram",
                    link: true,
                    path: "https://t.me/mutualdebts"
                }],
                web3: null,
                walletList: [{
                    tit: "BNB Smart Chain",
                    icon: "icon-qianbao1"
                }, {
                    tit: "TRON Smart Chain",
                    icon: "icon-qianbao1"
                }]
            }
        },
        computed: {
            ...mapState('user', ['userInfo', 'username', 'signCode', 'auditInfo', 'isJoin', 'isContribute', 'isMe', 'logoutUrl','token']),
            status() {
                if (this.userInfo && this.userInfo.userinfo) {
                    return this.userInfo.userinfo.status;
                }
                return 0;
            }
        },
        mounted() {
            if (this.token) {
                this.info();
            }
        },
        methods: {
            ...mapMutations('user', ['setData', 'logout']),
            ...mapActions('user', ['login', 'info']),
            verifySignature(message, address, signature, useTronHeader = true) {
                message = message.replace(/^0x/, '');
                signature = signature.replace(/^0x/, '');
                const messageBytes = [
                    ...toUtf8Bytes(useTronHeader ? TRX_MESSAGE_HEADER : ETH_MESSAGE_HEADER),
                    ...utils.code.hexStr2byteArray(message)
                ];
                console.log("messageBytes",messageBytes);
            },
            handleCommand(command) {
                console.log("command",command);
                if (command.tit == 'TRON Smart Chain') {
                    this.connectTronWallet();
                } else {
                    this.connectWallet();
                }
            },
            async connectWallet() {
                let provider = Init();
                // let web3 = new this.Web3(provider);
                if (provider.chainId == "0x38") {
                    this.getCode('bsc',window.ethereum.selectedAddress);
                    //const signedString = await web3.eth.personal.sign(web3.utils.utf8ToHex("Welcome to Mutuals!\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:0x8d3766440f0d7b949a5e32995d09619a7f861521\n\nNonce:d0d9ffb6-5e49-4e73-8eec-750ac0495f30"), this.username);
                   // alert("签名成功"+signedString);
                } else {
                    this.$message({
                        message: "Please switch to BSC mainnet",
                        type: "info",
                    });
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [{chainId: '0x38'}],// 主网id：0x38、测试网id：0x61
                    });
                }
            },
            async getTronWeb() {
                let tronWeb;
                if (window.tronLink.ready) {
                    tronWeb = window.tronLink.tronWeb;
                } else {
                    const res = await window.tronLink.request({ method: 'tron_requestAccounts' });
                    if (res.code === 200) {
                        tronWeb = window.tronLink.tronWeb;
                    }else{
                        console.log("连接错误");
                    }
                }
                return tronWeb;
            },
            async connectTronWallet(){
                // let web3 = new this.Web3();
                if(window.tronWeb){
                    this.tronWeb = window.tronWeb;
                    let walletAddress = this.tronWeb.defaultAddress.base58;
                    try {
                        this.getCode('tron',walletAddress);
                    } catch (e) {
                        console.log("签名失败",e);
                    }

                }else{
                    this.$message({
                        message: "Please follow TRON Wallet",
                        type: "info",
                    });
                }
            },
            async getisJsin() {
                let provider = window.ethereum || window.web3.currentProvider;
                this.web3 = new this.Web3(provider);
                let myContract = await new this.web3.eth.Contract(
                    avatar.ABI,
                    this.configCon.contract_address
                );
                let isJoin = await myContract.methods.balanceOf(this.username).call();
                this.setData({isJoin});
            },
            async initData(myContract) {
                let config = await myContract.methods.configView().call();
                jsonToUnderline(config);

                function jsonToUnderline(obj) {
                    Object.keys(obj).forEach((key) => {
                        let newKey = underline2Hump(key)
                        if (newKey !== key) {
                            obj[newKey] = obj[key]
                            delete obj[key]
                        }
                    });
                    return obj;
                }

                function underline2Hump(s) {
                    return s.replace(/_(\w)/g, function (all, letter) {
                        return letter;
                    })
                }

                this.setData({auditInfo: {...config}});
            },
            async getCode(type,walletAddress) {
                let loading = this.$loading({
                    lock: true,
                    text: "Log in...",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let web3;
                if(type==='tron') {
                     web3 = new this.Web3();
                }else{
                    let provider = window.ethereum || window.web3.currentProvider;
                    web3 = new this.Web3(provider);
                }
                let res = await this.api.auth().authNonce({address: walletAddress});
                if (res.data.code == 200) {
                    const nonce = res.data.data;
                    let str = "Welcome to Mutuals!\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:"+walletAddress+"\n\nNonce:"+nonce+"";
                    let str1 = "Welcome to Mutuals!\\n\\nThis request will not trigger a blockchain transaction or cost any gas fees.\\n\\nYour authentication status will reset after 24 hours.\\n\\nWallet address:"+walletAddress+"\\n\\nNonce:"+nonce+"";
                    // let str2 = "Welcome to Mutuals!\\n\\nThis request will not trigger a blockchain transaction or cost any gas fees.\\n\\nYour authentication status will reset after 24 hours.\\n\\nWallet address:"+walletAddress+"\\n\\nNonce:"+nonce+"";
                    const message = web3.utils.toHex(str); // any hex string
                    const message1 = web3.utils.toHex(str1);
                    // const message2 =  web3.utils.toHex(str2);
                    // console.log("message",message);
                    // console.log("message2",message2);
                    let signedString;
                    if(type==='tron'){
                        try {
                            signedString = await  this.tronWeb.trx.sign(message1);
                        } catch (e) {
                            loading.close();
                            this.$message({
                                message: "Please complete the signature to view the data",
                                type: "info",
                            });
                            return;
                        }
                    }else{
                        try {
                            signedString = await web3.eth.personal.sign(message, walletAddress);
                        } catch (e) {
                            loading.close();
                            this.$message({
                                message: "Please complete the signature to view the data",
                                type: "info",
                            });
                            return;
                        }
                    }
                    console.log("nonce",nonce);
                    try {
                        let loginRes = await this.login({
                            address: walletAddress, nonce, sign: signedString
                        });
                        if (loginRes.data.code == 200) {
                            this.setData({username:walletAddress});
                            loading.close();
                            this.$message({
                                message: "login successful",
                                type: "success",
                            });
                        }
                    }catch (e) {
                        loading.close();
                        this.$message({
                            message: "the address is wrong",
                            type: "error",
                        });
                    }

                }
            },
            toHome() {
                this.$router.push("/");
            },
            handleClose(done) { //关闭
                done();
            },
            toPath(item) {
                if (item.link) {
                    window.open(item.path);
                } else {
                    this.$router.push(item.path);
                }
                this.drawer = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .el-dropdown-menu__item {
        min-width: 80px;
        padding: 0 10px;
        color: #212833;

        .iconfont {
            margin-right: 10px;
            font-size: 12px;
        }
    }

    .header {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 66;
        width: 100%;
        height: 73px;
        background-color: #ffffff;
        display: flex;
        justify-content: center;

        .header-content {
            width: 1200px;
            height: 73px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .header-left {
                display: flex;
                align-items: center;

                .logo {
                    cursor: pointer;
                    font-size: 30px;
                    img {
                        width: 100%;
                    }
                }
            }

            .haeder-right {
                display: flex;
                align-items: center;

                .nav-item {
                    position: relative;
                    margin-right: 50px;
                    display: block;
                    font-size: 16px;
                    text-decoration: none;
                    color: #000000;
                    cursor: pointer;
                }

                .nav-item:hover {
                    color: #3A56FF;
                }

                .link-wallet {
                    width: 68px;
                    height: 48px;
                    background: #3A56FF;
                    border-radius: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #ffffff;
                    cursor: pointer;

                    .iconfont {
                        margin-right: 5px;
                        font-size: 25px;
                    }
                }

                .user-wallet {
                    width: auto;
                    padding: 0 12px;
                    border-radius: 4px;
                    @extend .link-wallet;
                    font-size: 18px;
                }

                .more {
                    position: relative;
                    margin-right: 36px;
                    cursor: pointer;

                    .iconfont {
                        font-weight: bold;
                    }

                    .notify {
                        position: absolute;
                        right: -8px;
                        top: -6px;
                        width: 16px;
                        height: 12px;
                        background: #D0050D;
                        border-radius: 7px;
                        border: 1px solid #FFFFFF;
                        text-align: center;
                        line-height: 12px;
                        color: #FFFFFF;
                        font-size: 10px;
                    }
                }
            }
        }
    }

    .avatar-container {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding-left: 32px;
        flex-direction: column;
        justify-content: center;


        .logo {
            margin-bottom: 20px;
            height: 33px;

            font-size: 30px;
            img {
                width: 100%;
            }
        }

        .link-wallet {
            width: 235px;
            height: 68px;
            background: #3A56FF;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            padding: 0 24px;
            color: #FFFFFF;
            font-size: 20px;
            cursor: pointer;

            .iconfont {
                margin-right: 15px;
                font-size: 24px;
            }
        }

        .line {
            width: 236px;
            height: 1px;
            background-color: #EBEDFC;
        }
    }

    .nav-wrap {
        margin-top: 40px;
        width: 100%;

        .nav-item {
            position: relative;
            display: block;
            width: 100%;
            height: 68px;
            line-height: 68px;
            box-sizing: border-box;
            padding: 0 32px;
            font-size: 18px;
            color: #0F0F0F;
            font-weight: 600;
            text-decoration: none;
            cursor: pointer;
            text-transform: uppercase;

            .border {
                display: none;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -10px;
                width: 2px;
                height: 20px;
                background-color: #3A56FF;
            }

            .prompt {
                position: absolute;
                top: 50%;
                right: 33px;
                margin-top: -3px;
                border-radius: 50%;
                width: 6px;
                height: 6px;
                background: #D0050D;
            }
        }

        .nav-item:hover .border {
            display: block;
        }
    }

    .noFixed {
        position: static;
        z-index: auto;
    }

    @media screen and (max-width: 1200px) {
        .header .header-content .haeder-right .nav-item {
            display: none;
        }
        .header .header-content {
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;
        }
    }

    @media screen and (max-width: 750px) {
        /*.header .header-content .haeder-right .link-wallet {*/
        /*    display: none;*/
        /*}*/
        .header .header-content .header-left .logo{
            font-size: 18px;
        }
    }
</style>
<style lang="scss">
    .header {
        .el-drawer {
            width: 299px !important;
        }
    }
</style>
