import axios from '../axios.js';

const api = function (token) {
    return {
        /**
         * 1.0.获取nonce
         * /api/auth/nonce
         **/
        async authNonce({address}) {
            return await axios.get("/api/auth/nonce", {
                params: {
                    address
                }
            })
        },
        /**
         * 1.0.用户信息
         * /api/auth/userinfo
         **/
        async userInfo() {
            return await axios.get("/api/auth/userinfo", {
                params: {},
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
        },

        /**
         * 1.2.登录
         * /api/auth/token
         * @param {string} address - 地址
         * @param {string} code     -  登陆code
         * @param {string} sign     -  签名
         **/
        async login({nonce, sign, address}) {
            return await axios.post("/api/auth/issue-token", {
                address, nonce, sign
            })
        },
        /**
         * 1.3.获取登陆CODE
         * api/auth/code
         * @param {string} address -  地址
         **/
        async getSummary() {
            return await axios.get("/api/account/summary", {
                params: {},
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
        },
        /**
         * 1.3绑定
         * api/auth/code
         * @param {string} address -  地址
         **/
        async bindInvite({
                             privateKey,
                             type
                         }) {
            return await axios.post("/api/account/bind-invitee",{
                private_key:privateKey,
                type
            }, {
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
        },
        /**
         * 1.3.获取登陆CODE
         * api/auth/code
         * @param {string} address -  地址
         **/
        async getCode({address}) {
            return await axios.get("/api/auth/code", {
                params: {address},
                // headers: {
                //     "Authorization": "Bearer " + token
                // }
            })
        },

        /**
         * 1.4 提案列表
         * api/proposal/index
         **/
        async proposalIndex({id, category}) {
            return await axios.get(id ? "/api/proposal/index/" + id : "/api/proposal/index", {
                params: {
                    category
                },
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
        },

        /**
         * 1.4.1 提案列表
         * api/proposal/index
         **/
        async commonConfig() {
            return await axios.get("/api/common/config", {
                params: {},
                headers: {}
            })
        },
        /**
         * 1.4.2 我发布的提案
         * api/proposal/owner
         **/
        async proposalOwner() {
            return await axios.get("/api/proposal/owner", {
                params: {},
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
        },

        /**
         * 1.5.提案申请
         * /api/proposal/store
         * @param {number} type - 提案隐私 允许值: 1隐私, 0公开
         * @param {number} category -  提案分类 允许值: 1治理, 0提案
         * @param {number} amt -  申请金额
         * @param {string} address - 收款地址
         * @param {string} title -  项目名称
         * @param {string} link -  项目链接
         * @param {string} logo -  项目logo
         * @param {string} name -   姓名
         * @param {string} email -  邮箱
         * @param {string} job     -  职位
         * @param {string} describe -  项目描述
         * @param {string} other     -  其他信息
         * @param {Number} has_investor -  投资状态  允许值: 0否, 1是
         * @param {string} file     -  其他文件
         **/
        async proposalStore(obj) {
            Object.keys(obj).forEach((item) => {
                if (!obj[item] && obj[item] != 0) delete obj[item];
            });
            return await axios.post("/api/proposal/store", obj, {
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
        },
        /**
         * 1.5.申请加入
         * /api/userinfo/store
         * @param {string} name - 名字
         * @param {string} introduce - 简介
         * @param {string} job - 职位
         * @param {string} tele_phone - 电话
         * @param {string} twitter -  推特
         * @param {string} recommender -   推荐人
         * @param {string} email -  邮箱
         * @param {string} birthday     -  生日
         * @param {string} id_front_uri -  正面图片链接
         * @param {string} id_back_uri -  背面图片链接
         * @param {string} id_number     -  身份证号
         * @param {string} company -  机构名称
         * @param {string} phone     -  电话
         **/
        async userinfoStore(obj) {
            Object.keys(obj).forEach((item) => {
                if (!obj[item]) delete obj[item];
            });
            return await axios.post("/api/userinfo/store", obj, {
                headers: {
                    "Authorization": "Bearer " + token
                }
            })
        }
    }
}
export default api
