<template>
    <div class="footer">
        <ul class="footer-left">
            <li class="item" v-for="(item,index) in footerList" @click="toPath(item)" :key="index">
                <span>{{item.title}}</span>
            </li>
        </ul>
        <ul class="external-link">
            <li class="external-link-items" v-for="(item,index) in externalLink" @click="toPath(item)" :key="index">
                <i class="iconfont" :class="item.icon"></i>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "myfooter",
        data() {
            return {
                footerList: [{
                    title: `ⓒ${new Date().getFullYear()} MTUAL DEBT`,
                }, {
                    title: "Twitter",
                    path: "https://twitter.com/esmdym6"
                }, {
                    title: "Telegram",
                    path: "https://t.me/mutualdebts"
                }],
                externalLink: [ {
                    icon: "icon-telegram",
                    path: "https://t.me/mutualdebts"
                }, {
                    icon: "icon-twitter",
                    path: "https://twitter.com/esmdym6"
                }]
            }
        },
        methods: {
            toPath(item) {
                if (item.path) {
                    window.open(item.path);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
        width: 100%;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #000000;
        padding: 0 171px;
        box-sizing: border-box;

        .footer-left {
            display: flex;
            align-items: center;
            color: #FFFFFF;
            font-size: 16px;

            .item {
                margin-right: 28px;
                cursor: pointer;

                span {
                    text-decoration: underline;
                }
            }

            .item:nth-of-type(1) {
                cursor: default;

                span {
                    text-decoration: none;
                }
            }
        }

        .external-link {
            display: flex;
            align-items: center;

            .external-link-items {
                margin-right: 30px;
                cursor: pointer;

                .iconfont {
                    color: #FFFFFF;
                    font-size: 25px;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .footer {
            height: 120px;
            /*padding: 15px;*/
            padding: 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .footer-left {
                flex-wrap: wrap;
                justify-content: center;

                .item:nth-of-type(1) {
                    margin-bottom: 10px;
                    width: 100%;
                    text-align: center;
                }
            }

            .external-link {
                margin-top: 15px;
            }
        }
    }
</style>
