import axios from "axios";
import {Message, Loading} from "element-ui";
import stores from "../store";
import router from "../router";

let baseURL;
if (process.env.NODE_ENV === 'development') {
    console.log('开发环境');
    baseURL = 'https://adminaxcs.mutual-debt.com';
} else {
    console.log('生产环境');
    baseURL = 'https://adminaxcs.mutual-debt.com';
}
const instance = axios.create({
    baseURL
});
let num = 0;
//请求拦截器
instance.interceptors.request.use((config) => {
    // config.headers.common['Accept'] = "application/json";
    return config;
}, error => {
    return Promise.reject(error)
});
// 响应拦截器
instance.interceptors.response.use((response) => {
    if (response.data) {
        console.log("response.data",response.data);
        if (response.data.code == -200) {
            num++;
            if (num == 1) {
                Message({
                    message: response.data.message,
                    type: "error",
                    offset:100
                });
                num = 0;
            }
        }
        if (response.data.code != 200) {
            num++;
            if (num == 1) {
                Loading.close();
                Message({
                    message: response.data.message,
                    type: "error",
                    offset:100
                });
                num = 0;
            }
        }
        if (response.data.code == 401) {
            num++;
            if (num == 1) {
                stores.commit('user/logout'); //退出登录
                router.push('/');
                num = 0;
            }
        }
    }
    return response;
}, (error) => {
    if (error.response) {
        Message.error({
            message: error.response.data.message,
            type: "error"
        });
        if (error.response.data.code == 401) {
            num++;
            if (num == 1) {
                stores.commit('user/logout'); //退出登录
                router.push('/');
                num = 0;
            }
        }
    }
    return Promise.reject(error)
})

export default instance;
